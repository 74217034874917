import { createApp } from "vue"
import { createPinia } from "pinia"

import App from "./App.vue"
import router from "@/lib/router"
import VueGtag from "vue-gtag-next"
import "@/style/global.scss"
import "@/types/IsCotizador"
import "@/types/isStore"
import FloatingVue from "floating-vue"
import "floating-vue/dist/style.css"
import "viewerjs/dist/viewer.css"
import VueViewer from "v-viewer"

const Mode = import.meta.env.MODE

const app = createApp(App)
  .use(router)
  .use(createPinia())
  .use(FloatingVue)
  .use(VueViewer)
  .use(VueGtag, {
    property: {
      id: Mode === "production" ? "G-0K8G9BZH87" : "F-0K8G9BZH87",
    },
    useDebugger: false,
  })

app.mount("#app")
