import axios from "axios"
import dayjs from "dayjs"
import { getCookie } from "./lib/useLogin"

type ApiConfig = {
  withCredentials: boolean
  headers: {
    "Content-Type": string
  }
  baseURL: string
  media: string
}

const refreshTkn = async () => {
  try {
    await authcall.post("/auth/token/refresh/", {})
    console.log("%c  🍺 Refreshing  ", "background-color: #244D39; color: white;")
  } catch (err) {
    console.log(err)
  }
}

const Mode = import.meta.env.MODE

const baseConfig = () => {
  if (Mode === "development") {
    return {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
      baseURL: import.meta.env.VITE_DEV_API,
      media: import.meta.env.VITE_DEV_MEDIA,
    }
  }
  if (Mode === "staging") {
    return {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
      baseURL: import.meta.env.VITE_STAGE_API,
      media: import.meta.env.VITE_STAGE_MEDIA,
    }
  }
  if (Mode === "production") {
    return {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
      baseURL: import.meta.env.VITE_PROD_API,
      media: import.meta.env.VITE_PROD_MEDIA,
    }
  }
  return {} as ApiConfig
}

export const authcall = axios.create(baseConfig())

const http = axios.create(baseConfig())

http.interceptors.request.use(
  async (config) => {
    const access_token_expiration = getCookie("access_token_expiration")
    const refresh_token_expiration = getCookie("refresh_token_expiration")

    if (refresh_token_expiration) {
      if (dayjs().isAfter(dayjs(access_token_expiration)) || !access_token_expiration) {
        await refreshTkn()
      }
    }

    return config
  },
  (error) => {
    console.log("error", error)
    return Promise.reject(error)
  },
)

export default http
