import ApiDataService from "@/lib/apiDataService"

export const homeCall = async () => {
  try {
    const resp = await ApiDataService.getMany("data/home/")
    console.info(
      "%c  Cotizador Delta E Lab  " + "%c v" + import.meta.env.VITE_APPVERSION + " ",
      "background-color: #8d0bb3; color: white;",
      "background-color: #fff; color: black;",
    )
    return resp.data.SettingsGlobal[0]
  } catch (error) {
    console.log("Se ha producido un error")
    console.log(error)
    return
  }
}
