<template>
  <div class="card-body">
    <div class="d-flex justify-content-center py-5">
      <div class="spinner-border" style="width: 3rem; height: 3rem" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <blockquote class="blockquote">
        <p>espere un momento</p>
      </blockquote>
    </div>
  </div>
</template>
