import http from "@/http-common"
export default {
  logIn(Auth: any): Promise<any> {
    return http.post("/auth/login/", Auth)
  },
  logOut(): Promise<any> {
    return http.post("/auth/logout/")
  },
  refresh(tkn?: any): Promise<any> {
    return http.post("/auth/token/refresh/", { tkn })
  },
  validation(tkn: string): Promise<any> {
    return http.post("/auth/token/verify/", { token: tkn })
  },
  getUser(): Promise<any> {
    return http.get("/auth/user/")
  },
  getProfile(): Promise<any> {
    return http.get("/store/profile/")
  },
}
export function getCookie(name: string): string | null {
  const cookieArray: string[] = document.cookie.split(";") // Split the cookie string into an array
  for (const cookie of cookieArray) {
    const cookiePair: string[] = cookie.split("=") // Split each key-value pair
    if (name === cookiePair[0]?.trim()) {
      return decodeURIComponent(cookiePair[1] as string) // Return the value
    }
  }
  return null // Return null if the cookie is not found
}
