type parcel = {
  height: number
  length: number
  weight: number
  width: number
}

type shppingQuotes = {
  amount_local: string
  currency_local: string
  days: number
  extra_dimension_price: number
  ferri_price: number
  insurance_fee: number
  is_ocurre: boolean
  out_of_area_pricing: number
  out_of_area_service: boolean
  parcel: parcel
  provider: string
  service_level_code: string
  service_level_name: string
  total_pricing: number
  zip_to: string
}

export class sData_Base {
  static default() {
    return {
      CartSale: [] as any,
      Cart: [] as any,
      onTotalPromo: null as any,
      CartTotal: 0,
      shipping: {
        quotes: [] as shppingQuotes[],
        guias: [],
      },
      selectedAddress: {
        id: "",
        Direccion: {
          Linea_1: "",
          Linea_2: "",
          Colonia: "",
          CP: "",
          Municipio: "",
          Estado: "",
          Reference: "",
          Pais: "México",
        },
        default: true,
      },
    }
  }

  static defaultShipping() {
    return {
      quotes: [] as shppingQuotes[],
      guias: [],
    }
  }

  static defaultShippingQuote() {
    return {
      manual: false,
      addShipping: false,
      action: "quote",
      parcel: {
        weight: 0,
        height: 0,
        width: 0,
        length: 0,
      },
      dataEnvio: {
        Colonia: "",
        CP: "",
        Estado: "",
        Linea_1: "",
        Linea_2: "",
        Municipio: "",
        Pais: "México",
        Reference: "",
      }
    }
  }

  static defaultSelectedAddress() {
    return {
      id: "",
      default: true,
      Direccion: {
        Linea_1: "",
        Linea_2: "",
        Colonia: "",
        CP: "",
        Municipio: "",
        Estado: "",
        Reference: "",
        Pais: "México",
      },
    }
  }
}
