const DECIMALES: any = [1, 10, 100, 1000, 10000]
const Mode = import.meta.env.MODE

export class Redondeo {
  static floor = (Valor: number, Decimales: number) => {
    // eslint-disable-next-line no-param-reassign
    if (Decimales > DECIMALES.length) Decimales = 0
    let number = Valor * DECIMALES[Decimales]
    number = Math.floor(number)
    number = number / DECIMALES[Decimales]
    return number
  }
  static ceil = (Valor: number, Decimales: number) => {
    // eslint-disable-next-line no-param-reassign
    if (Decimales > 4) Decimales = 0
    let number = Valor * DECIMALES[Decimales]
    number = Math.ceil(number)
    number = number / DECIMALES[Decimales]
    return number
  }
  static round = (Valor: number, Decimales: number) => {
    // eslint-disable-next-line no-param-reassign
    if (Decimales > 4) Decimales = 0
    let number = Valor * DECIMALES[Decimales]
    number = Math.round(number)
    number = number / DECIMALES[Decimales]
    return number
  }
}

export class Analytics {
  static addCart = (Item: any) => {
    const data = {
      seleccion: Item.Seleccion,
      cantidad: Item.Piezas,
      precio: Item.subtotal,
      precioPieza: Item.precioPieza,
    }
    return data
  }
}

export class Tools {
  static fullPath = (endpoint: string | null, Default?: string): any => {
    if (endpoint === null) return null
    const hadURL = "https://"
    const baseURL =
      Mode === "development"
        ? import.meta.env.VITE_DEV_MEDIA
        : Mode === "staging"
          ? import.meta.env.VITE_STAGE_MEDIA
          : import.meta.env.VITE_PROD_MEDIA
    if (endpoint === null) return null
    if (endpoint === undefined && Default !== undefined) return Default
    if (!endpoint) return ""
    if (endpoint.includes(hadURL)) return endpoint
    return baseURL + endpoint
  }

  static selectionToSlug = (info: any) => {
    const slug = `${info.Marca.toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(
        / /g,
        "-",
      )}-${info.Name.toLowerCase().replace(/ /g, "-")}-${info.Gramaje.toLowerCase()}`
    return slug
  }

  static openInNewTab = (url: string) => {
    const win = window.open(url, "_blank")
    if (win !== null) win.focus()
  }

  static papeles = (Cart: any, CartSale: any) => {
    const papers: string[] = []
    Cart.forEach((element: any) => {
      if (!papers.includes(" " + element.Seleccion.Name)) {
        papers.push(" " + element.Seleccion.Name)
      }
    })
    CartSale.forEach((element: any) => {
      if (!papers.includes(" " + element.Seleccion.Name)) {
        papers.push(" " + element.Seleccion.Name)
      }
    })
    return papers
  }
}

export class Generators {
  static id = (prefix: string) => {
    let ID = prefix + "_"
    const characters = "abcdefghijklmnopqrstuvwxyz0123456789"
    for (let i = 0; i < 6; i++) {
      ID += characters.charAt(Math.floor(Math.random() * 36))
    }
    return ID
  }
}

export type rSize = {
  Ancho: number
  Largo: number
  Unidad: number
}

export class Medidas {
  static txtUnit = (unit: number) => (unit === 2.54 ? "cm" : unit === 1 ? "in" : "error")

  static txtUnitFull = (unit: number) =>
    unit === 2.54 ? "Centimetros" : unit === 1 ? "Pulgadas" : "error"

  static sizeTxt = (medidas: rSize) => {
    const Unidad = this.txtUnit(medidas.Unidad)
    return `${medidas.Ancho}x${medidas.Largo}${Unidad}`
  }

  static conversionShow = (medidas: any) => {
    if (medidas.Unidad === 1) {
      const Ancho = Redondeo.round(medidas.Ancho * 2.54, 2)
      const Largo = Redondeo.round(medidas.Largo * 2.54, 2)
      const Unidad = 2.54
      return `${Ancho}x${Largo}${this.txtUnit(Unidad)}`
    } else {
      const Ancho = Redondeo.round(medidas.Ancho / 2.54, 2)
      const Largo = Redondeo.round(medidas.Largo / 2.54, 2)
      const Unidad = 1
      return `${Ancho}x${Largo}${this.txtUnit(Unidad)}`
    }
  }

  static sizeNormalize = (medidas: any) => {
    const Ancho = Math.min(medidas.Ancho, medidas.Largo)
    const Largo = Math.max(medidas.Ancho, medidas.Largo)
    return { Ancho, Largo, Unidad: medidas.Unidad }
  }

  static Inches = (Medidas: any, Margen: Margen) => {
    const Ancho = Medidas.Ancho / Medidas.Unidad
    const Largo = Medidas.Largo / Medidas.Unidad
    const detectMayor = Math.max(Ancho, Largo)
    const detectMenor = Math.min(Ancho, Largo)
    const margen = (Margen.margen / Margen.unidad) * 2
    const conMargen = {
      ladoMayor: detectMayor + margen,
      ladoMenor: detectMenor + margen,
    }
    const sinMargen = {
      ladoMayor: detectMayor,
      ladoMenor: detectMenor,
    }
    return { conMargen, sinMargen }
  }

  static SelectedInches = (Store: any) => {
    const Size: any = this.Inches(Store.Medidas, Store.Margen)
    let final = Size.sinMargen
    if (Store.Margen.interiorORexterior === "exterior") {
      final = Size.conMargen
    }
    return final
  }

  static Area = (sizeInches: any) => {
    return Redondeo.round(sizeInches.ladoMayor * sizeInches.ladoMenor, 2)
  }
}

export class Rollos {
  static newlistRollos = (allRollos: any) => {
    const byLinea = allRollos.reduce((Newlinea: any, rollo: any) => {
      const linea = rollo.Linea.Name
      // eslint-disable-next-line no-param-reassign
      if (Newlinea[linea] === undefined) Newlinea[linea] = []
      Newlinea[linea].push(rollo)
      return Newlinea
    }, {})
    return byLinea
  }

  static listRollos = (allRollos: Rollo[]) => {
    const todos = []
    for (const rol of allRollos) {
      const Rollo = rol
      let precios = []
      let agotado = []
      for (const prices of rol.precios) {
        if (prices.Disponible === true) {
          precios.push(prices)
        }
        if (prices.Disponible === false) {
          agotado.push(prices)
        }
      }
      precios = precios.sort((a, b) => (b.size > a.size ? 1 : -1))
      agotado = agotado.sort((a, b) => (b.size > a.size ? 1 : -1))
      Rollo.precios = precios
      Rollo.agotado = agotado
      Rollo.stock = true
      if (precios.length <= 0 && agotado.length > 0) {
        Rollo.precios = agotado
        Rollo.stock = false
      }
      if (precios.length > 0 || agotado.length > 0) {
        todos.push(Rollo)
      }
    }

    return todos
  }
}

export class CartOperations {
  static sumit = (Item: any) => {
    return JSON.parse(JSON.stringify(Item))
  }

  static sumitCustom = (Item: any) => {
    const Order: any = {
      Type: "Manual",
      Data: {
        Nombre: Item.Nombre,
        Descripcion: Item.Descripcion,
        Size: {
          Ancho: Item.Ancho,
          Largo: Item.Largo,
          Unidad: Item.Unidad,
        },
      },
      Piezas: Item.Piezas,
      Seleccion: {
        Name: Item.Nombre,
        Size: Item.Size,
      },
      precioPieza: Item.precioPieza,
    }
    return JSON.parse(JSON.stringify(Order))
  }
}

export class Piezas {
  static itemsDisponibles = (items: any) => {
    const Disponible = items.filter((item: any) => {
      return item.Disponible === true
    })
    const Agotados = items.filter((item: any) => {
      return item.Disponible === false
    })
    return { Disponible, Agotados }
  }
}

export class Validations {
  static email = (address: string) => {
    const regxEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
    return regxEmail.test(address)
  }
}
