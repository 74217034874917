import { Redondeo } from "./commons"

export class Papeles {
  static classificador = (descuentos: any) => {
    const enCategoria = [] as any
    const enRollo = [] as any
    const enMontaje = [] as any
    const enLaminado = [] as any
    const enSpray = [] as any
    let enTotal = [] as any
    const enMultiple = [] as any
    for (const promo of descuentos) {
      if (
        promo.Categoria &&
        !promo.Rollo &&
        !promo.Montaje &&
        !promo.Laminado &&
        !promo.Spray &&
        !promo.MedidasSTD
      ) {
        enCategoria.push(promo)
      } else if (
        promo.Rollo &&
        !promo.Categoria &&
        !promo.Montaje &&
        !promo.Laminado &&
        !promo.Spray &&
        !promo.MedidasSTD
      ) {
        enRollo.push(promo)
      } else if (
        promo.Montaje &&
        !promo.Categoria &&
        !promo.Rollo &&
        !promo.Laminado &&
        !promo.Spray &&
        !promo.MedidasSTD
      ) {
        enMontaje.push(promo)
      } else if (
        promo.Laminado &&
        !promo.Categoria &&
        !promo.Rollo &&
        !promo.Montaje &&
        !promo.Spray &&
        !promo.MedidasSTD
      ) {
        enLaminado.push(promo)
      } else if (
        promo.Spray &&
        !promo.Categoria &&
        !promo.Rollo &&
        !promo.Montaje &&
        !promo.Laminado &&
        !promo.MedidasSTD
      ) {
        enSpray.push(promo)
      } else if (promo.porTotal) {
        enTotal.push(promo)
      } else {
        enMultiple.push(promo)
      }
    }
    // si en enTotal existe mas de un elemento ordernarlos por el que su valor interno percent sea mayor al menor
    if (enTotal.length > 1) {
      enTotal = enTotal.sort((a: any, b: any) => a.porTotal - b.porTotal)
      let promo_anterior = enTotal[0]
      const finalEnTotal = []
      for (let i = 1; i < enTotal.length; i++) {
        if (i === 1) finalEnTotal.push(promo_anterior)
        if (enTotal[i].percent > promo_anterior.percent) {
          finalEnTotal.push(enTotal[i])
          promo_anterior = enTotal[i]
        }
      }
      enTotal = finalEnTotal
    }
    return {
      enCategoria,
      enRollo,
      enMontaje,
      enLaminado,
      enSpray,
      enTotal,
      enMultiple,
    }
  }

  static onRollo = (promotions: any, rollo_slug: string) => {
    let discounts = promotions.filter((discount: any) => discount.Rollo === rollo_slug)
    if (discounts && discounts.length === 1) return discounts[0]
    if (discounts && discounts.length > 1) {
      discounts = discounts.sort((a: any, b: any) => b.percent - a.percent)
      return discounts[0]
    }
    return []
  }

  static onMultipleValidation = (Selection: isSelected, promotion: any) => {
    if (
      Object.keys(promotion).includes("MedidasSTD") &&
      (Selection.Medidas.Ancho !== promotion.MedidasSTD.Ancho ||
        Selection.Medidas.Largo !== promotion.MedidasSTD.Largo ||
        Selection.Medidas.Unidad !== promotion.MedidasSTD.Unidad)
    )
      return
    if (
      ((!Selection.Rollo.Sprayable || !Selection.Botones.Spray) &&
        Object.keys(promotion).includes("Spray")) ||
      (Selection.Botones.Spray &&
        Selection.Spray.Slug !== promotion.Spray &&
        Object.keys(promotion).includes("Spray"))
    )
      return
    if (
      ((!Selection.Rollo.Laminable || !Selection.Botones.Laminado) &&
        Object.keys(promotion).includes("Laminado")) ||
      (Selection.Botones.Laminado &&
        Selection.Laminado.Slug !== promotion.Laminado &&
        Object.keys(promotion).includes("Laminado"))
    )
      return
    if (
      (!Selection.Botones.Montaje && Object.keys(promotion).includes("Montaje")) ||
      (Selection.Botones.Montaje &&
        Selection.Montaje.Slug !== promotion.Montaje &&
        Object.keys(promotion).includes("Montaje"))
    )
      return
    if (
      Selection.Rollo.Linea.Categoria.Slug !== promotion.Categoria &&
      Object.keys(promotion).includes("Categoria")
    )
      return
    if (Selection.Rollo.Slug !== promotion.Rollo && Object.keys(promotion).includes("Rollo")) return

    return promotion
  }

  static isApplicable = (Selection: isSelected, promotions: any) => {
    const todos = [
      ...promotions.enCategoria,
      ...promotions.enRollo,
      ...promotions.enMontaje,
      ...promotions.enLaminado,
      ...promotions.enSpray,
      ...promotions.enMultiple,
    ]
    let multiple = [] as isPromotion[]

    for (const promo of todos) {
      const isValid = this.onMultipleValidation(Selection, promo)
      if (isValid) {
        // checar si en multiple ya existe con los mismos parametros, ignorando id, active, public, percent, nombre y mensaje
        // si existe comparar el porcentaje y quedarse con el mayor, si no existe agregarlo
        const existe = multiple.find((item: any) => {
          if (
            item.Categoria === isValid.Categoria &&
            item.Rollo === isValid.Rollo &&
            item.Montaje === isValid.Montaje &&
            item.Laminado === isValid.Laminado &&
            item.Spray === isValid.Spray &&
            item.MedidasSTD === isValid.MedidasSTD
          ) {
            return item
          }
        })
        if (existe && existe.percent) {
          if (existe.percent < isValid.percent) {
            multiple = multiple.filter((item: any) => item.id !== existe?.id)
            multiple.push(isValid)
          }
        } else multiple.push(isValid)
      }
    }
    return multiple
  }

  static sumInternal = (discount: any) => {
    let total = 0
    for (const key of Object.keys(discount)) {
      total += discount[key]
    }
    return total
  }

  static applyDiscounts = (Costos: any, promotions: any) => {
    const Multi = []
    const Descuentos = []
    let acumulable = true
    let exclusive = false
    let acumulable_multiple = true
    let acumulable_exclusive = false
    const SingleKeys = ["Categoria", "Rollo", "Montaje", "Laminado", "Spray", "MedidasSTD"]
    const discount_single = {
      Rollo: 0,
      Montaje: 0,
      Laminado: 0,
      Spray: 0,
    }
    let discount_multiple = {
      Rollo: 0,
      Montaje: 0,
      Laminado: 0,
      Spray: 0,
    }
    let discount_final = {
      Rollo: 0,
      Montaje: 0,
      Laminado: 0,
      Spray: 0,
    }
    const percent_single = {
      Rollo: 0,
      Montaje: 0,
      Laminado: 0,
      Spray: 0,
    }
    let percent_multiple = {
      Rollo: 0,
      Montaje: 0,
      Laminado: 0,
      Spray: 0,
    }
    let percent_final = {
      Rollo: 0,
      Montaje: 0,
      Laminado: 0,
      Spray: 0,
    }
    for (const promo of promotions) {
      const keys = Object.keys(promo)
      const single = keys.filter((key: any) => SingleKeys.includes(key))
      if (single.length > 1) {
        Multi.push(promo)
      } else {
        Descuentos.push(promo)
      }
    }

    // Descuentos individuales
    const byCategoria = Descuentos.find((promo: any) => promo.Categoria)
    const byRollo = Descuentos.find((promo: any) => promo.Rollo)
    if (byCategoria) {
      if (byRollo) {
        if (byCategoria.percent > byRollo.percent) {
          discount_single.Rollo = Redondeo.round(Costos.print * (byCategoria.percent / 100), 1)
          percent_single.Rollo = byCategoria.percent
          acumulable = byCategoria.acumulable === false ? false : acumulable
          exclusive = byCategoria.exclusive === true ? true : exclusive
        } else {
          ;(discount_single.Rollo = Redondeo.round(Costos.print * (byRollo.percent / 100), 1)),
            (percent_single.Rollo = byRollo.percent)
          acumulable = byRollo.acumulable === false ? false : acumulable
          exclusive = byCategoria.exclusive === true ? true : exclusive
        }
      } else {
        discount_single.Rollo = Redondeo.round(Costos.print * (byCategoria.percent / 100), 1)
        percent_single.Rollo = byCategoria.percent
        acumulable = byCategoria.acumulable === false ? false : acumulable
        exclusive = byCategoria.exclusive === true ? true : exclusive
      }
    } else if (byRollo) {
      discount_single.Rollo = Redondeo.round(Costos.print * (byRollo.percent / 100), 1)
      percent_single.Rollo = byRollo.percent
      acumulable = byRollo.acumulable === false ? false : acumulable
      exclusive = byRollo.exclusive === true ? true : exclusive
    }
    if (Costos.acabados) {
      if (Costos.acabados.Montaje) {
        const byMontaje = Descuentos.find((promo: any) => promo.Montaje)
        if (byMontaje) {
          discount_single.Montaje = Redondeo.round(
            Costos.acabados.Montaje * (byMontaje.percent / 100),
            1,
          )
          percent_single.Montaje = byMontaje.percent
          acumulable = byMontaje.acumulable === false ? false : acumulable
          exclusive = byMontaje.exclusive === true ? true : exclusive
        }
      }
      if (Costos.acabados.Laminado) {
        const byLaminado = Descuentos.find((promo: any) => promo.Laminado)
        if (byLaminado) {
          discount_single.Laminado = Redondeo.round(
            Costos.acabados.Laminado * (byLaminado.percent / 100),
            1,
          )
          percent_single.Laminado = byLaminado.percent
          acumulable = byLaminado.acumulable === false ? false : acumulable
          exclusive = byLaminado.exclusive === true ? true : exclusive
        }
      }
      if (Costos.acabados.Spray) {
        const bySpray = Descuentos.find((promo: any) => promo.Spray)
        if (bySpray) {
          discount_single.Spray = Redondeo.round(Costos.acabados.Spray * (bySpray.percent / 100), 1)
          percent_single.Spray = bySpray.percent
          acumulable = bySpray.acumulable === false ? false : acumulable
          exclusive = bySpray.exclusive === true ? true : exclusive
        }
      }
    }

    // Descuentos multiples
    if (Multi.length > 0) {
      let discountMul = [] as any
      for (const promo of Multi) {
        const promo_validation = {
          percents: { Rollo: 0, Montaje: 0, Laminado: 0, Spray: 0 },
          values: { Rollo: 0, Montaje: 0, Laminado: 0, Spray: 0 },
          acumulable: true,
          exclusive: false,
        }
        if ((promo.Categoria && promo.Rollo) || promo.Rollo) {
          promo_validation.values.Rollo = Redondeo.round(Costos.print * (promo.percent / 100), 1)
          promo_validation.percents.Rollo = promo.percent
          promo_validation.acumulable =
            promo.acumulable === false ? false : promo_validation.acumulable
          promo_validation.exclusive = promo.exclusive === true ? true : promo_validation.exclusive
        }
        if ((promo.Categoria && promo.Montaje) || promo.Montaje) {
          promo_validation.values.Montaje = Redondeo.round(
            Costos.acabados.Montaje * (promo.percent / 100),
            1,
          )
          promo_validation.percents.Montaje = promo.percent
          promo_validation.acumulable =
            promo.acumulable === false ? false : promo_validation.acumulable
          promo_validation.exclusive = promo.exclusive === true ? true : promo_validation.exclusive
        }
        if ((promo.Categoria && promo.Laminado) || promo.Laminado) {
          promo_validation.values.Laminado = Redondeo.round(
            Costos.acabados.Laminado * (promo.percent / 100),
            1,
          )
          promo_validation.percents.Laminado = promo.percent
          promo_validation.acumulable =
            promo.acumulable === false ? false : promo_validation.acumulable
          promo_validation.exclusive = promo.exclusive === true ? true : promo_validation.exclusive
        }
        if ((promo.Categoria && promo.Spray) || promo.Spray) {
          promo_validation.values.Spray = Redondeo.round(
            Costos.acabados.Spray * (promo.percent / 100),
            1,
          )
          promo_validation.percents.Spray = promo.percent
          promo_validation.acumulable = promo.acumulable
          promo_validation.exclusive = promo.exclusive ? true : false
        }
        discountMul.push(promo_validation)
      }
      // sumar los descuentos de cada promocion y quedarse con el mayor y asignarlo a discount_multiple
      discountMul = discountMul.sort((a: any, b: any) => {
        const aTotal = a.values.Rollo + a.values.Montaje + a.values.Laminado + a.values.Spray
        const bTotal = b.values.Rollo + b.values.Montaje + b.values.Laminado + b.values.Spray
        return bTotal - aTotal
      })

      discount_multiple = discountMul[0].values
      percent_multiple = discountMul[0].percents
      acumulable_multiple = discountMul[0].acumulable
      acumulable_exclusive = discountMul[0].exclusive
    }
    // suma de los descuentos
    const sum_single = this.sumInternal(discount_single)
    const sum_multiple = this.sumInternal(discount_multiple)

    if (sum_multiple > 0 && sum_single > 0) {
      for (const key of Object.keys(discount_multiple)) {
        const fkey = key as keyof typeof discount_multiple
        discount_final[fkey] =
          discount_multiple[fkey] > discount_single[fkey]
            ? discount_multiple[fkey]
            : discount_single[fkey]
        percent_final[fkey] =
          discount_multiple[fkey] > discount_single[fkey]
            ? percent_multiple[fkey]
            : percent_single[fkey]
      }
    } else if (sum_multiple > 0) {
      discount_final = discount_multiple
      percent_final = percent_multiple
      acumulable = acumulable_multiple
      exclusive = acumulable_exclusive
    } else {
      discount_final = discount_single
      percent_final = percent_single
    }

    return { discount_final, percent_final, acumulable, exclusive }
  }
}

export class Canvas {
  static aplication_filter = (Selection: any, promotion: any) => {
    if (Object.keys(promotion).includes("Rollo") && Selection.Rollo.Slug !== promotion.Rollo) return
    else if (
      Object.keys(promotion).includes("MedidasSTD") &&
      (Selection.Medidas.Ancho !== promotion.MedidasSTD.Ancho ||
        Selection.Medidas.Largo !== promotion.MedidasSTD.Largo ||
        Selection.Medidas.Unidad !== promotion.MedidasSTD.Unidad)
    )
      return
    if (
      Object.keys(promotion).includes("MaterialBastidor") &&
      Selection.Madera !== promotion.MaterialBastidor
    )
      return
    if (Object.keys(promotion).includes("Canto") && Selection.Canto.Slug !== promotion.Canto) return
    if (Object.keys(promotion).includes("Barniz") && Selection.Barniz.Slug !== promotion.Barniz)
      return
    if (Selection.Bastidor) {
      if (!Object.keys(Selection.Bastidor).includes("Piezas") && promotion.stock) return
    }
    return promotion
  }

  static applyDiscounts = (Costos: number, Selection: any, promotions: any) => {
    let acumulable = true
    const promos = [] as any
    for (const promo of promotions) {
      const isValid = this.aplication_filter(Selection, promo)
      if (isValid) promos.push(isValid)
    }
    const promo = promos.sort((a: any, b: any) => b.percent - a.percent)[0]
    if (promo) {
      const discount = Redondeo.round(Costos * (promo.percent / 100), 1)
      const total = Redondeo.round(Costos - discount, 1)
      acumulable = promo.acumulable === false ? false : acumulable
      return { discount, promo, total, acumulable }
    }
    return { discount: 0, promo: { percent: 0 }, total: Costos, acumulable }
  }
}
