<template>
  <div class="container p-2 mb-2">
    <div id="site" class="custom-card">
      <header-nav v-if="Globals.Logo && !isLoading" :logo="Globals.Logo" @logout="logout()" />
      <div>
        <div v-if="loadingGlobal">
          <loading-spin />
        </div>
        <div v-else class="card-body shadow-lg">
          <RouterView v-slot="{ Component }">
            <transition name="slidefade">
              <component :is="Component" v-if="!loadingGlobal" />
            </transition>
          </RouterView>
          <div v-if="router.currentRoute.value.name !== 'cart' && onTotalPromos && errorPage === false">
            <anuncios
              v-if="isExclusive"
              mensaje-error=""
              bold-error="¡Promocíon exclusiva no acumulable con otras promociones!"
              icon="shout"
              color="alert-danger"
            />
            <anuncios
              v-else-if="onTotalPromos.promos.length > 0"
              :mensaje-error="onTotalPromos.promos[0].mensaje"
              bold-error=""
              icon="shout"
              color="alert-info"
            />
            <anuncios
              v-else-if="onTotalPromos.activePromo && onTotalPromos.activePromo.percent > 0"
              mensaje-error=""
              :bold-error="`¡Felicidades tu pedido tiene el ${onTotalPromos.activePromo.percent}% de descuento!`"
              icon="shout"
              color="alert-success"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="Usr.access_token_expiration !== null">
    <div v-if="Usr.is_superuser" class="container">
      <div v-if="router.currentRoute.value.name === 'cart'" class="card my-2">
        <pos-panel />
      </div>
    </div>
  </div>

  <Footer />

  <ModalGen
    v-if="component2Mount"
    ref="ModalDiv"
    :model-value="component2Mount"
    :settings="ModalSettings"
    :custom="ModalSettings.custom"
    @action="orderChange()"
    @custom-bool="customBool($event)"
    @login="logout_expire()"
  />
</template>

<script lang="ts" setup>
import { onBeforeMount, ref, defineAsyncComponent, watch } from "vue"
import { storeToRefs } from "pinia"
import { RouterView, useRouter } from "vue-router"
import { useCart } from "@/store/cartStore"
import { useCotizadorData } from "./store/CotizadorStore"
import { promiseTimeout, useSessionStorage } from "@vueuse/core"
import { homeCall } from "./lib/dataFetch"
import { fullCall, getCookie } from "@/lib/useLogin"
import dayjs from "dayjs"
import auth from "@/lib/apiAuth"
import apiDataService from "./lib/apiDataService"
import loadingSpin from "@/components/elements/isLoading.vue"

// Componentes
const posPanel = defineAsyncComponent(() => import("@/components/posPanel.vue"))
const headerNav = defineAsyncComponent(() => import("@/components/headerNav.vue"))
const anuncios = defineAsyncComponent(() => import("@/components/elements/anunciosAlerts.vue"))
const Footer = defineAsyncComponent(() => import("@/components/siteFooter.vue"))
const ModalGen = defineAsyncComponent(() => import("@/components/elements/modalGen.vue"))

const router = useRouter()
const lastPromos = useSessionStorage("lastPromos", "")
const lastPromosUser = useSessionStorage("lastPromoUser", "")

const { onTotalPromos, isLoading, promos_papel, promos_canvas, cartID, isExclusive, cliente } =
  storeToRefs(useCart())
const { ModalSettings, component2Mount, showModal, Globals, Usr, loadingGlobal, errorPage } =
  storeToRefs(useCotizadorData())

const logout = async () => {
  try {
    loadingGlobal.value = true
    await auth.logOut()
    console.clear()
    useCart().restore()
    useCotizadorData().restore()
    await homeCall()
    await fullCall()
    loadingGlobal.value = false
  } catch (errors: any) {
    console.log(errors)
    return errors.message
  }
}

function orderChange() {
  if (ModalSettings.value.custom) {
    ModalSettings.value.custom[0].order = ModalSettings.value.custom[0].order == "+" ? "-" : "+"
  }
  return
}

const customBool = (val: string) => {
  switch (val) {
    case "showMode":
      ModalSettings.value.showStatus = !ModalSettings.value.showStatus
      break
  }
}

const logout_expire = async () => {
  let refresh_token_expiration = getCookie("refresh_token_expiration")
  let access_token_expiration = getCookie("access_token_expiration")
  if (refresh_token_expiration) {
    if (dayjs().isAfter(dayjs(refresh_token_expiration))) {
      logout()
      return
    }
    let timeout = dayjs(refresh_token_expiration).diff(dayjs(), "millisecond")
    await promiseTimeout(timeout)
    logout()
  }
  if (access_token_expiration && !refresh_token_expiration) {
    logout()
  }
}

const getPromos = async () => {
  try {
    const endpoint = []
    let haveClient = false
    if (cliente.value && cliente.value.id) {
      if (
        (dayjs().diff(dayjs(lastPromosUser.value), "minutes") > 1 || !lastPromosUser.value) &&
        !cartID.value
      ) {
        endpoint.push(`data/promos/cliente/${cliente.value.id}/`)
        haveClient = true
      }
    }
    if (dayjs().diff(dayjs(lastPromos.value), "minutes") > 1 || !lastPromos.value) {
      endpoint.push("data/promos/")
    }
    if (endpoint) {
      const resp = await apiDataService.parallelGet(endpoint)
      if (haveClient && !cartID.value) {
        let client_promos = resp[`data/promos/cliente/${cliente.value.id}/`]
        promos_papel.value.Cliente = [...client_promos.Papeles, ...client_promos.porTotal]
        promos_canvas.value.Cliente = client_promos.Canvas
        lastPromosUser.value = dayjs().toString()
      }
      if (resp["data/promos/"]) {
        let promos = resp["data/promos/"]
        promos_papel.value.General = [...promos.Papeles, ...promos.porTotal]
        promos_canvas.value.General = promos.Canvas
        lastPromos.value = dayjs().toString()
      }
    }
  } catch (error) {
    console.log(error)
  }
}

const ModalDiv = ref()

onBeforeMount(async () => {
  Globals.value = await homeCall()
  await getPromos()
  await logout_expire()
})

router.afterEach(async () => {
  await getPromos()
})

watch(ModalDiv, async () => {
  if (showModal.value) {
    ModalDiv.value.show()
  }
})
</script>

<style>
.custom-card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  border: 3px solid #6c757d !important;
  border-radius: 0.5rem !important;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  background-color: #fff;
  background-clip: border-box;
}
</style>
