import { createRouter, createWebHistory, Router } from "vue-router"
import { trackRouter } from "vue-gtag-next"

const Fotograficos = () => import("@/pages/TabFotograficos.vue")
const FineArt = () => import("@/pages/TabFineArt.vue")
// const Productos = () => import ('@/pages/TabProductos.vue')
const Login = () => import("@/pages/offLogin.vue")
const Canvas = () => import("@/pages/TabCanvas.vue")
const Cart = () => import("@/pages/TabCart.vue")
const CanvasStock = () => import("@/components/canvasTabs/stockTab.vue")
const CanvasCustom = () => import("@/components/canvasTabs/customTab.vue")
const getCotizacion = () => import("@/pages/getCotizacion.vue")
const privGetCotizacion = () => import("@/pages/admin/getCotizacion.vue")
const isError = () => import("@/pages/isError.vue")

const routes = [
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
  {
    //  el path debe ser un numero de 3 digitos
    path: "/:errorCode(.{3})",
    name: "error",
    component: isError,
    meta: {
      title: "Error",
    },
  },
  {
    path: "/",
    redirect: "/fotograficos",
  },
  {
    path: "/fotograficos",
    name: "fotograficos",
    component: Fotograficos,
    meta: {
      title: "Papeles Fotográficos",
      linkName: "Fotográficos",
      group: "NavBar",
    },
  },
  {
    path: "/fineart",
    name: "fineart",
    component: FineArt,
    meta: {
      title: "Papeles Fine Art",
      linkName: "Fine Art",
      group: "NavBar",
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: "Login Delta E Lab",
    },
  },
  {
    path: "/canvas",
    name: "canvas",
    component: Canvas,
    meta: {
      title: "Canvas",
      linkName: "Canvas",
      group: "NavBar",
    },
    children: [
      {
        path: "stock",
        name: "stockcanvas",
        component: CanvasStock,
      },
      {
        path: "custom",
        name: "customcanvas",
        component: CanvasCustom,
      },
    ],
  },
  // {
  //   path: "/productos",
  //   name: "productos",
  //   component: Productos,
  //   meta: {
  //     title: "Productos",
  //     linkName: "Productos",
  //     group: "NavBar"
  //   }
  // },
  {
    path: "/cart",
    name: "cart",
    component: Cart,
    meta: {
      title: "Resumen cotización",
      linkName: "Cotización",
      group: "NavBar",
    },
  },
  {
    path: "/c/:otp",
    name: "cotizacion",
    component: getCotizacion,
  },
  // Private routes
  {
    path: "/pc/:uuid",
    name: "get Cotizacion Publica",
    component: privGetCotizacion,
  },
]

const router: Router = createRouter({
  history: createWebHistory(),
  routes,
})

router.afterEach((to) => {
  const baseTitle = "Delta E Lab // Calculadora de Precios"

  if (to.name === "index") {
    document.title = baseTitle
  } else {
    document.title = `${to.meta.title} | ${baseTitle}`
  }
})

trackRouter(router)

export default router
