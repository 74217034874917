import { defineStore } from "pinia"
import { ref, computed, onBeforeMount, watch } from "vue"
import { Redondeo } from "@/lib/commons"
import { sData_Base } from "@/lib/setDefaults"
import { useSessionStorage, useLocalStorage } from "@vueuse/core"
import { Papeles } from "@/lib/promotions"
import { getCookie } from "@/lib/useLogin"
import ApiDataService from "@/lib/apiDataService"

export const useCart = defineStore("getCart", () => {
  // Verificamos el ambiente de la app
  const refresh_data_expiration = computed(() => {
    // get the ambient for Dev and Staging use 1 minute for productions use 35 minutes
    const env = import.meta.env.MODE
    if (env === "development" || env === "staging") return 1
    return 35
  })
  const cliente = useSessionStorage("cliente", {} as any)
  const sData = useSessionStorage("SDATA", sData_Base.default())
  const cartID = useSessionStorage("cartID", "")
  const promos_papel = useSessionStorage("discounts", { General: [] as any, Cliente: [] as any })
  const promos_canvas = useSessionStorage("discounts_canvas", {
    General: [] as any,
    Cliente: [] as any,
  })
  const Usr = useLocalStorage("userData", {} as any)
  const manualPromo = ref<any>(null)
  const lastPromosUser = useSessionStorage("lastPromoUser", "")
  const isLoading = ref(false)
  const hideFooter = ref(false)
  const isExclusive = ref(false)
  const shippingQuotes = ref<any>([])
  const parcelQuotation = ref(sData_Base.defaultShippingQuote())

  const promotionsPapel = computed(() => {
    return Papeles.classificador([...promos_papel.value.General, ...promos_papel.value.Cliente])
  })
  const promotionsCanvas = computed(() => {
    return [...promos_canvas.value.General, ...promos_canvas.value.Cliente]
  })

  const shippingCost = computed(() => {
    let shipping = 0
    if (sData.value.shipping.quotes.length > 0) {
      for (const guia of sData.value.shipping.quotes) {
        shipping = shipping + guia.total_pricing
      }
    }
    return shipping
  })

  const cartSubTotals = computed(() => {
    let cartSubTotal = 0
    let saleSubTotal = 0
    let piezas = 0
    if (sData.value.Cart) {
      for (const item of sData.value.Cart) {
        cartSubTotal = Redondeo.round(cartSubTotal + item.final_costos.subTotal, 1)
        piezas = piezas + item.final_costos.piezas
      }
    }
    if (sData.value.CartSale) {
      for (const item of sData.value.CartSale) {
        saleSubTotal = Redondeo.round(saleSubTotal + item.final_costos.subTotal, 1)
        piezas = piezas + item.final_costos.piezas
      }
    }
    return { cartSubTotal, saleSubTotal, piezas }
  })

  const onTotalPromos = computed(() => {
    if (promotionsPapel.value.enTotal.length === 0) {
      return { activePromo: { percent: 0 }, promos: [] }
    }
    const enTotalPromos = promotionsPapel.value.enTotal
    const cartSubtotal = cartSubTotals.value.cartSubTotal
    let activePromo = {} as any
    let promos = [] as any
    promos = enTotalPromos.filter((promo: any) => cartSubtotal < promo.porTotal)
    activePromo = enTotalPromos.findLast((promo: any) => cartSubtotal >= promo.porTotal)
    return { activePromo, promos }
  })

  const activePromo = computed(() => {
    if (manualPromo.value) {
      return manualPromo.value
    }
    if (sData.value.onTotalPromo) {
      return sData.value.onTotalPromo
    }
    if (cartID.value && sData.value.onTotalPromo) {
      return sData.value.onTotalPromo
    }
    if (onTotalPromos.value.activePromo) {
      return onTotalPromos.value.activePromo
    }
    return { percent: 0 }
  })

  watch(activePromo, (newVal) => {
    sData.value.onTotalPromo = newVal
  })

  const CartTotals = computed(() => {
    const totales = {
      cartSubTotal: cartSubTotals.value.cartSubTotal,
      saleSubTotal: cartSubTotals.value.saleSubTotal,
      descuento: 0,
      percent: 0,
      piezas: cartSubTotals.value.piezas,
      shipping: shippingCost.value,
      subTotalCart: 0,
      total: 0,
    } as any
    if (activePromo.value) {
      if (activePromo.value.porTotal < totales.cartSubTotal) {
        totales.percent = activePromo.value.percent
        totales.descuento = Redondeo.round(
          totales.cartSubTotal * (activePromo.value.percent / 100),
          1,
        )
      }
    }
    totales.subTotalCart = Redondeo.round(totales.cartSubTotal - totales.descuento, 1)
    totales.total = Redondeo.round(
      totales.subTotalCart + totales.saleSubTotal + totales.shipping,
      1,
    )
    return totales
  })

  watch(CartTotals, (newVal) => {
    sData.value.CartTotal = newVal
  })

  const differentDiscount = computed(() => {
    if (onTotalPromos.value.activePromo) {
      if (onTotalPromos.value.activePromo.percent > CartTotals.value.percent) {
        return true
      }
    }
    return false
  })

  watch(onTotalPromos, (newPromos) => {
    if (cartID.value) return
    sData.value.onTotalPromo = newPromos.activePromo
  })

  const restore = () => {
    sData.value = sData_Base.default()
    cartID.value = ""
    isLoading.value = false
    shippingQuotes.value = []
  }

  onBeforeMount(async () => {
    const cookie = getCookie("refresh_token_expiration")
    if (!Usr.value.id && cookie) {
      const userDetails = await ApiDataService.getMany("/auth/user/")
      Usr.value = userDetails.data
    }
    // checa si existe una promocion manuel en el pedido
    if (sData.value.onTotalPromo && sData.value.onTotalPromo.id === "manual") {
      manualPromo.value = sData.value.onTotalPromo
    }
  })

  return {
    cliente,
    lastPromosUser,
    promos_canvas,
    promotionsCanvas,
    promotionsPapel,
    CartTotals,
    sData,
    parcelQuotation,
    promos_papel,
    isLoading,
    cartID,
    shippingQuotes,
    restore,
    manualPromo,
    hideFooter,
    refresh_data_expiration,
    differentDiscount,
    onTotalPromos,
    activePromo,
    isExclusive,
  }
})

export default useCart
