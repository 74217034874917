import http from "@/http-common"
import axios from "axios"

class ApiDataService {
  getAll(): Promise<any> {
    return http.get("/data")
  }

  async parallelGet(endpoints: string[]): Promise<any> {
    const promises = endpoints.map((endpoint) => {
      const value = http.get("/" + endpoint)
      return value
    })
    return axios
      .all(promises)
      .then((results: any[]) => {
        const fullResponse: any = {}
        for (const result of results) {
          fullResponse[result.config.url.replace(/^\//, "")] = result.data
        }
        return fullResponse
      })
      .catch((error: any) => {
        throw error
      })
  }
  getMany(endpoint: string): Promise<any> {
    return http.get(endpoint)
  }
  patch(endpoint: string, data: any): Promise<any> {
    return http.patch(endpoint, data)
  }
  get(endpoint: string, id: any): Promise<any> {
    return http.get(`${endpoint}${id}`)
  }
  create(data: any, endpoint: string): Promise<any> {
    return http.post(endpoint, data)
  }
  post(endpoint: any, data: any): Promise<any> {
    return http.post(endpoint, data)
  }
  delete(endpoint: string): Promise<any> {
    return http.delete(`${endpoint}`)
  }
  update(endpoint: string, data: any): Promise<any> {
    return http.put(endpoint, data)
  }
  deleteAll(): Promise<any> {
    return http.delete(`/tutorials`)
  }
  findByTitle(title: string): Promise<any> {
    return http.get(`/tutorials?title=${title}`)
  }
}
export default new ApiDataService()
